import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { footerLinks } from '../Shared/footerLinks';
import { theme, style, config, StyledRadio } from '../../../config/settings.jsx';
import { steps } from '../../../config/steps';
import { api } from '../../../config/apis.jsx';
  

const Consented = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRetry, setIsLoadingRetry] = useState(false);
    const navigate = useNavigate();
    const [statusMessage, setStatusMessage] = useState('Checking your bank connection and authorising accounts');
    const [pageTitle, setPageTitle] = useState('Connecting...');
    const location = useLocation();
    const [stepperStep, setStepperStep] = useState(1);
    const [reconsentRequired, setReconsentRequired] = useState(false);
    const [logOut, setLogOut] = useState(false);
    const [newConsentLink, setNewConsentLink] = useState('');
  
    const fetchJob = async (retries = 0) => {
      const maxRetries = 5;
      const cdrid = localStorage.getItem('cdrid');
      const jobId = localStorage.getItem('jobId');
      try {
        const response = await axios.post(
         api.checkJob,
          { jobId: jobId, cdrid: cdrid },
          { headers: { 'Content-Type': 'application/json',   
            apikey: config.apikey,
            tenant: config.tenant } }
        );
  
        const status = response.status;
        if (status === 200) {
          setStepperStep(2);  
          setPageTitle('Finding Accounts!');
          setStatusMessage('Successfully connected - we are now retrieving your accounts.');
          const retrieveAccounts = await axios.post(
            api.retrieveAccounts,
            { cdrid: cdrid },
            { headers: { 'Content-Type': 'application/json',    
              apikey: config.apikey,
             tenant: config.tenant } }
          );
          if (retrieveAccounts.status === 200) {
            setPageTitle('Success!');
            setStatusMessage('Accounts have successfully been retrieved.');
            navigate('/roundup-accounts');
          }
        } else if (status === 202) {
          setPageTitle('Checking!');
          setStatusMessage('Receiving communications from your bank. This might take a little while.');
          if (retries < maxRetries) {
          setTimeout(() => fetchJob(jobId, retries + 1), 5000);
        } else {
          const findAccounts = await axios.post(
            api.retrieveAccounts,
            { cdrid: cdrid },
            { headers: { 'Content-Type': 'application/json',    apikey: config.apikey,
            tenant: config.tenant } }
          );
          if (findAccounts.status === 200) {
            setPageTitle('Success!');
            setStatusMessage('Accounts have successfully been retrieved.');
            navigate('/roundup-accounts');
          }
          setPageTitle('Verification');
          setStatusMessage('For security reasons we need you to verify your RoundA account by logging in.');
          setLogOut(true);
        } }
      } catch (error) {
        setPageTitle('Verification');
        setStatusMessage('For security reasons we need you to verify your RoundA account by logging in.');
        setLogOut(true);
      }
    };
  
    const findJobIds = async (cdrid) => {
      try {
        const response = await axios.post(
          api.findJobs,
          { cdrid: cdrid },
          { headers: { 'Content-Type': 'application/json',    
            apikey: config.apikey,
            tenant: config.tenant } },
        );
        const jobId = response.data.jobId;
        localStorage.setItem('jobId', jobId);
        const status = response.status;
  
        if (status === 200) {
          setStepperStep(2);
          setPageTitle('Confirming Connection');
          setStatusMessage('FOUND! Finalising connection with your bank');
          await fetchJob(jobId);
        }
      } catch (error) {
        setPageTitle('Verification');
        setStatusMessage('For security reasons we need you to verify your RoundA account by logging in.');
        setLogOut(true);
      }
    };

    const getUserRecord = async (userId) => {
      if (!userId) {
        navigate('/');
      }
      try {
        const response = await axios.post(
          api.getUser,
          { userId: userId },
          { headers: { 'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant
           } },

        );
        const cdridA = response.data.userRecord.document._id;
        localStorage.setItem('cdrid', cdridA);
        const clubId = response.data.userRecord.document.supportingClubId;
        console.log(clubId);
      
      }  catch (error) {
      }
    };
      
    useEffect(() => {
      const executeAsyncFunctions = async () => {
      const searchParams = new URLSearchParams(location.search);
      const userId = searchParams.get('userId');
      localStorage.setItem('userId', userId);
      await getUserRecord(userId);
      setPageTitle('Searching For Connection!');
      const cdrid = localStorage.getItem('cdrid');
      findJobIds(cdrid);
    };

    executeAsyncFunctions();
  }, [location.search]); 



  const handleRetry = async (cdrid) => {
    setIsLoadingRetry(true);
    try {
      const response = await axios.post(api.consent,
      {cdrid: cdrid},
      { headers: { 'Content-Type': 'application/json',
        apikey: config.apikey,
            tenant: config.tenant
       } }); // Replace with your actual API endpoint
      const consentLink = response.data.consentLink;
      window.location.href = consentLink // Adjust based on your API response structure
    } catch (error) {
      console.error('Error creating consent Link:', error);
    } finally {
      setIsLoadingRetry(false)
    }
  };

  const logOutButton = async () => {
    navigate('/logout');
  };



  return (
    <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
 
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
      <Grid container justifyContent="center" mb={2}>
      {/*}
      <Grid item xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center" mb={4}>
      <img src={Logo} alt="Logo"  style={{ width: '70%', height: 'auto'}} />
  </Box></Grid> */}
         <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
        <Stepper activeStep={stepperStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      
      <Typography variant="h4" component="h2" align="center" mt={2} sx={{fontWeight: 'bold'}} gutterBottom>
      {pageTitle}
      </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={16} style={{ width: '100%'}}>
      <Typography variant="body2" component="h2" align="center"  gutterBottom>
       {statusMessage}
      </Typography>
      {reconsentRequired && (
                <Button
                onClick={handleRetry}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
                disabled={isLoadingRetry}
    
                      
              >
                {isLoadingRetry ? 'Loading...' : 'Retry'}
             
              </Button>
              )}
        {logOut && (
                <Button
                onClick={logOutButton}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
              
    
                      
              >
              Log In
             
              </Button>
              )}
   
      </Box>

      
    
      </Grid>
      



                    
        {footerLinks}
        </Box>
    </Container></ThemeProvider>
  );
};

export default Consented;

export {};