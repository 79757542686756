import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import LoginPage from './components/screens/Auth/Login';
import RegisterUser from './components/screens/Auth/Register';
import ClubSelect from './components/screens/Auth/Clubs';
import LaunchConsent from './components/screens/Onboarding/LaunchConsent';
import Dashboard from './components/screens/App/Dashboard';
import LogoutPage from './components/screens/Auth/Logout';
import LinkedAccounts from './components/screens/App/LinkedAccounts';
import Consented from './components/screens/Onboarding/Consented';
import RoundupAccounts from './components/screens/Onboarding/RoundUpAccounts';
import FundingAccounts from './components/screens/Onboarding/FundingAccounts';
import Finished from './components/screens/Onboarding/Finished';
import Support from './components/screens/App/Support';
import History from './components/screens/App/History';
import { analytics } from './firebaseConfig';
import Terms from './components/screens/App/Terms';
import { Analytics } from "@vercel/analytics/react"



const App = () => {


  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/:variable" element={<LoginPage />} />
        <Route path="/register" element={<ClubSelect />} />
        <Route path="/register2" element={<RegisterUser />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/launch-consent" element={<LaunchConsent />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/linked-accounts" element={<LinkedAccounts />} />
        <Route path="/consented" element={<Consented />} />
        <Route path="/roundup-accounts" element={<RoundupAccounts />} />
        <Route path="/funding-accounts" element={<FundingAccounts />} />
        <Route path="/finished" element={<Finished />} />
        <Route path="/support" element={<Support />} />
        <Route path="/history" element={<History />} />
        <Route path="/terms" element={<Terms />} />
      

      </Routes>
    </Router>
  );

};
<Analytics />

export default App;
